<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid"/> {{caption}}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 20 : 8"
        :dark="dark"
        pagination
      >
        <template #status="{item}">
          <td>
            <CBadge :color="getBadge(item.status)">{{item.status}}</CBadge>
          </td>
          <td class="text-center">
            <router-link
                    :to="{
                      name: 'individualDTR',
                      params: { pID: item.BiometricID },
                    }"
                    >
              <i class="bi bi-eye-fill">
              </i> 
            </router-link>
          </td>
        </template>
      </CDataTable>
        <!-- <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Personnel ID</th>
              <th scope="col">Last Name</th>
              <th scope="col">First Name</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="item.personnelID"
            >
              <th scope="row">{{ item.personnelID }}</th>
              <td>{{ item.LastName }}</td>
              <td>{{ item.FirstName }}</td>
              <td>Active</td>
              <td><i class="bi bi-eye-fill ml-auto"></i></td>
            </tr>
          </tbody>
        </table> -->
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'Table',
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return ['BiometricID', 'FirstName', 'LastName', 'status', 'action']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    }
  }
}
</script>
